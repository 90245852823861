import { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Banner, Button, ThemeProviderContext, useToast } from '@knack/asterisk-react';

import { type SampleApp } from '@/types/apps';
import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useSession } from '@/hooks/useSession';
import { usePostSignUpContext } from '@/contexts/PostSignUpContext';
import { shouldSendAnalytics } from '@/utils/analytics';
import { getErrorMessage } from '@/utils/errors';
import { isFlagEnabled } from '@/utils/flagsmith';
import { rudderStackAnalytics } from '@/utils/rudderstack';
import { getSampleWelcomePath } from '@/utils/samples';
import { FullPageSpinner } from '@/components/ui/FullPageSpinner';
import { AiModal } from '@/pages/apps/create-app/ai-form/AiModal';
import { AppCreateType, BUILDER_NEXT_IMPORT_PATH } from '@/pages/apps/create-app/constants';
import { getTemplateAppsFilteredAndSorted } from './setupWizardHelper';
import { TemplateCard } from './TemplateCard';

interface StepSelectCardProps {
  selectedIndustry: string;
  selectedUseCase: string;
  sampleApps: SampleApp[];
  defaultPathForTranslation: string;
  onBack: () => void;
  getTitleFromFilter: (key: string) => string;
  aiCardFlagValue?: 'personalized' | 'generate_ai';
}

export function StepSelectCard({
  selectedIndustry,
  selectedUseCase,
  sampleApps,
  defaultPathForTranslation,
  onBack,
  getTitleFromFilter,
  aiCardFlagValue
}: StepSelectCardProps) {
  const [t] = useTranslation();
  const session = useSession();
  const { presentToast } = useToast();
  const { data: account } = useAccountQuery();
  const { createAppFromSample, createApp, deleteApp } = useAppMutation();
  const { setIsSetupWizardEnabled } = usePostSignUpContext();
  const [selectedTemplate, setSelectedTemplate] = useState<SampleApp | undefined>(undefined);
  const [shouldShowAiModal, setShouldShowAiModal] = useState(false);
  const [appCreationError, setAppCreationError] = useState('');
  const [isAppLoading, setIsAppLoading] = useState(false);
  const [showAllTemplates, setShowAllTemplates] = useState(false);
  const { isDarkMode } = useContext(ThemeProviderContext);

  const templatesToDisplay = useMemo(
    () => getTemplateAppsFilteredAndSorted(selectedIndustry, sampleApps, showAllTemplates),
    [selectedIndustry, sampleApps, showAllTemplates]
  );

  const handleSampleCreateApp = () => {
    if (!selectedTemplate) return;
    const sampleData = {
      appId: selectedTemplate.id,
      templateSlug: selectedTemplate.slug,
      appDescription: selectedTemplate.description
    };

    if (account?.applications && account?.applications.length > 0) {
      // eslint-disable-next-line no-underscore-dangle
      const appId = account.applications[0]._id;
      deleteApp.mutate(appId);
    }
    createAppFromSample.mutate(sampleData, {
      onSuccess: (response) => {
        if (shouldSendAnalytics(session.user.email)) {
          void rudderStackAnalytics.track('Setup Wizard: New App From Sample Created', {
            app: selectedTemplate.slug,
            groupId: session.account.id,
            industry: t(
              `components.onboarding.questionnaire.questions.categories.options.${selectedIndustry.replace('-', '_')}`
            ),
            use_case: t(
              `components.onboarding.questionnaire.questions.categories.options.${selectedUseCase.replace('-', '_')}`
            )
          });
        }
        if (isFlagEnabled('full_nextgen_access')) {
          window.location.href = `${import.meta.env.PUBLIC_BUILDER_NEXT_URL}/${session.account.slug}/${response.app.slug}/${getSampleWelcomePath(selectedTemplate)}`;
        } else {
          window.location.href = `${import.meta.env.PUBLIC_BUILDER_URL}/${session.account.slug}/${response.app.slug}/${getSampleWelcomePath(selectedTemplate)}`;
        }
      },
      onError: (error) => {
        setAppCreationError(
          getErrorMessage(error, t('components.create_app.create_app_error_message'))
        );
        presentToast({ title: appCreationError, intent: 'destructive' });
      }
    });
  };

  const handleAppCreation = async (caseType: string) => {
    setIsSetupWizardEnabled(false);

    if (caseType === 'ai') {
      setShouldShowAiModal(true);
      return;
    }

    if (caseType === 'import') {
      setIsAppLoading(true);

      const appData = {
        appDescription: '',
        appOrigin: AppCreateType.Import,
        shouldCreateWithTable: true,
        shouldCreateDefaultUserRoles: true
      };

      createApp.mutate(appData, {
        onSuccess: (response) => {
          window.location.href = `${import.meta.env.PUBLIC_BUILDER_NEXT_URL}/${session.account.slug}/${response.application.slug}/${BUILDER_NEXT_IMPORT_PATH}?new=true`;
        },
        onError: (error) => {
          setAppCreationError(
            getErrorMessage(error, t('components.create_app.create_app_error_message'))
          );
          setIsAppLoading(false);
        }
      });
    }
  };

  if (isAppLoading) {
    return <FullPageSpinner />;
  }

  return (
    <div className="flex flex-col items-center gap-6 px-4 py-6" data-testid="step-select-card">
      {appCreationError && !isAppLoading && (
        <Banner
          data-testid="app-creation-error-banner"
          intent="destructive"
          title={t('components.create_app.create_app_error_title')}
          closeMode="text"
        >
          <Banner.Message className="text-xs">{appCreationError}</Banner.Message>
        </Banner>
      )}
      <div className="mx-auto grid max-w-screen-lg grid-cols-1 gap-6 pb-16 sm:grid-cols-2 lg:grid-cols-3">
        {templatesToDisplay.map((template) => (
          <TemplateCard
            data-testid={`${template.slug}-card`}
            key={template.id}
            type="template"
            template={template}
            selectedTemplateId={selectedTemplate?.id}
            getTitleFromFilter={getTitleFromFilter}
            onClick={() => setSelectedTemplate(template)}
          />
        ))}
        <TemplateCard
          type="customize"
          data-testid="customize-card"
          selectedUseCase={selectedUseCase}
          aiCardFlagValue={aiCardFlagValue}
          defaultPathForTranslation={defaultPathForTranslation}
          getTitleFromFilter={getTitleFromFilter}
          onClick={async () => {
            if (shouldSendAnalytics(session.user.email)) {
              await rudderStackAnalytics.track('Setup Wizard: Customize My App/AI clicked', {
                groupId: session.account.id
              });
            }
            void handleAppCreation('ai');
          }}
          isDarkMode={isDarkMode}
        />
        <TemplateCard
          type="import"
          defaultPathForTranslation={defaultPathForTranslation}
          isDarkMode={isDarkMode}
          onClick={() => {
            if (shouldSendAnalytics(session.user.email)) {
              void rudderStackAnalytics.track('Setup Wizard: Start with a Table (Import) clicked', {
                groupId: session.account.id
              });
            }
            void handleAppCreation('import');
          }}
        />
        {templatesToDisplay.length <= 3 && !showAllTemplates && (
          <TemplateCard
            data-testid="more-templates-card"
            type="more"
            selectedIndustry={selectedIndustry}
            defaultPathForTranslation={defaultPathForTranslation}
            getTitleFromFilter={getTitleFromFilter}
            onClick={() => setShowAllTemplates(true)}
          />
        )}
      </div>

      <div className="fixed bottom-0 flex h-16 w-full items-center justify-end bg-base px-7 shadow-sm">
        <Button intent="minimal" onClick={onBack} data-testid="back-button">
          {t('actions.back')}
        </Button>
        <Button
          disabled={!selectedTemplate || createAppFromSample.isPending}
          onClick={handleSampleCreateApp}
          isLoading={createAppFromSample.isPending}
          className="ml-4"
          data-testid="create-app-button"
        >
          {t('components.create_app.create_app')}
        </Button>
      </div>

      {shouldShowAiModal && (
        <AiModal open onClose={() => setShouldShowAiModal(false)} data-testid="ai-modal" />
      )}
    </div>
  );
}
