import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';
import { usePostUpdatePlanContext } from '@/contexts/PostUpdatePlanContext';
import { isFlagEnabled } from '@/utils/flagsmith';

async function updatePlan(planId: string, accountId: string) {
  const { data } = await axios.post(
    `/v1/accounts/${accountId}/billing/plan`,
    { plan: planId },
    {
      withCredentials: true
    }
  );

  return data;
}

export function useUpdatePlanMutation() {
  const session = useSession();
  const queryClient = useQueryClient();
  const { setShouldShowAutoIncreaseBanner, hasBannerBeenShown, setHasBannerBeenShown } =
    usePostUpdatePlanContext();
  return useMutation({
    mutationFn: (planId: string) => updatePlan(planId, session.account.id),
    onSuccess: (data) => {
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.account]
      });
      void queryClient.invalidateQueries({
        queryKey: [queryKeys.billing.overview]
      });
      const isFlowsEnabled = isFlagEnabled('albato_flows');
      if (
        isFlowsEnabled &&
        !data.account.product_plan?.id.includes('starter_') &&
        !hasBannerBeenShown
      ) {
        setShouldShowAutoIncreaseBanner(true);
        setHasBannerBeenShown(true);
      }
    }
  });
}
