import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { Route } from '@/enums';
import { queryKeys } from '@/hooks/api/queryKeys';

type LogoutResponse = {
  logout: boolean;
};

type LogoutParams = {
  accountId: string;
  userId: string;
  isSharedBuilderOnly?: boolean;
};

async function postLogoutUser({ accountId, userId, isSharedBuilderOnly }: LogoutParams) {
  const url = isSharedBuilderOnly
    ? `/v1/accounts/shared/session/${userId}`
    : `/v1/accounts/${accountId}/session/${userId}`;

  const { data } = await axios.delete<LogoutResponse>(url, {
    withCredentials: true
  });

  return data;
}

export function useLogoutUserMutation() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { search } = useLocation();

  return useMutation({
    mutationFn: postLogoutUser,
    onSuccess: (data: LogoutResponse) => {
      if (data.logout) {
        void queryClient.resetQueries({
          queryKey: [queryKeys.auth.session]
        });

        // Remove query data from the cache, but not inform observers about it
        queryClient.removeQueries();

        window.HubSpotConversations?.widget?.remove();
        navigate({ pathname: `/${Route.SignIn}`, search });
      }
    }
  });
}
