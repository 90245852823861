import { useEffect, useRef, useState } from 'react';
import { type UseFormRegister } from 'react-hook-form';
import { Textarea } from '@knack/asterisk-react';

import { type AiInputSchemaType } from '@/pages/apps/create-app/ai-form/AiInput';

interface TextAreaAIAnimatedProps {
  title: string;
  disabled: boolean;
  placeholder: string;
  intent: 'destructive' | 'default';
  maxLength: number;
  register: UseFormRegister<AiInputSchemaType>;
  onChange: (value: string) => void;
}

export function TextAreaAIAnimated({
  title,
  disabled,
  placeholder,
  intent,
  maxLength,
  register,
  onChange
}: TextAreaAIAnimatedProps) {
  const [placeholderText, setPlaceholderText] = useState('');
  const [userInput, setUserInput] = useState('');
  const currentTextRef = useRef('');

  useEffect(() => {
    if (placeholder && userInput === '') {
      let index = 0;
      currentTextRef.current = '';
      setPlaceholderText('');
      const interval = setInterval(() => {
        if (index < placeholder.length) {
          currentTextRef.current += placeholder[index];
          setPlaceholderText(currentTextRef.current);
          index += 1;
        } else {
          clearInterval(interval);
        }
      }, 30);
      return () => clearInterval(interval);
    }
    return setPlaceholderText(placeholderText);
  }, [placeholder, userInput]);

  return (
    <Textarea
      className="h-[286px] flex-none resize-none placeholder-transparent"
      autoFocus
      hideResize
      style={{ pointerEvents: 'all' }}
      title={title}
      placeholder={placeholderText}
      intent={intent}
      maxLength={maxLength}
      {...register('query')}
      showCharCount
      disabled={disabled}
      onChange={(e) => {
        const { value } = e.target;
        setUserInput(value);
        onChange(value);
      }}
    />
  );
}
