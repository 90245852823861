import { useTranslation } from 'react-i18next';
import { HiArrowTopRightOnSquare as ExternalLinkIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

interface TaxExemptionMessageProps {
  isTaxExempt?: boolean;
}

export function TaxExemptionMessage({ isTaxExempt = false }: TaxExemptionMessageProps) {
  const [t] = useTranslation();

  return (
    <>
      <p className="mb-6">
        {isTaxExempt ? (
          <>
            {t('components.billing.payment.tax_exemption1')}
            <a
              href="https://www.knack.com/tax-exemption"
              target="_blank"
              rel="noopener noreferrer"
              className="font-medium underline"
            >
              {t('components.billing.payment.tax_exemption2')}
            </a>
            {t('components.billing.payment.tax_exemption3')}
          </>
        ) : (
          <>{t('components.billing.payment.tax_exemption4')}</>
        )}{' '}
        {t('components.billing.payment.tax_exemption5')}
      </p>
      <p className="mb-6">{t('components.billing.payment.tax_exemption6')}</p>
      <p>{t('components.billing.payment.tax_exemption7')}</p>

      {!isTaxExempt && (
        <Button asChild intent="secondary">
          <a
            href="https://www.knack.com/pricing/non-profit-package/"
            target="_blank"
            rel="noopener noreferrer"
            className="mt-6 inline-flex items-center justify-center px-4 py-2 font-medium"
          >
            <ExternalLinkIcon className="mr-2" />
            {t('components.billing.payment.tax_exemption_apply')}
          </a>
        </Button>
      )}
    </>
  );
}
