import { type TFunction } from 'i18next';

import { type Account } from '@/types/account';

/**
 * Gets the renewal day based on the `datePaidPeriodEnd` attribute from transactions usage query response.
 * The day should be the day of the date + 1 day (check last day of the month)
 * @param date the date in the format of '2022-01-01'
 * @returns the renewal day
 */
export const getRenewalDay = (date: string) => {
  const [year, month, day] = date.split('-').map(Number);
  const lastDay = new Date(year, month, 0).getDate();
  return day === lastDay ? 1 : day + 1;
};

export const getFullTransactionsUsedBannerText = (
  accountData: Account,
  t: TFunction<'translation', undefined>,
  transactionsUsagePercentage: number
) => {
  const isAutoIncreaseNotAllowed =
    accountData.isTrial || accountData.product_plan?.id.includes('starter_');
  const isAutoIncreaseEnabled = accountData.billing.auto_increase.transactions;

  if (isAutoIncreaseNotAllowed) {
    return t('components.billing.overview.flows.warning_100_trial_starter');
  }

  if (transactionsUsagePercentage < 100) {
    return t(
      isAutoIncreaseEnabled
        ? 'components.billing.overview.flows.warning_auto_increase_enabled'
        : 'components.billing.overview.flows.warning_auto_increase_disabled',
      {
        percentage: transactionsUsagePercentage
      }
    );
  }

  return t(
    isAutoIncreaseEnabled
      ? 'components.billing.overview.flows.warning_100_others_autoincrease_enabled'
      : 'components.billing.overview.flows.warning_100_others_autoincrease_disabled'
  );
};
