import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useFlagsmith } from 'flagsmith/react';
import LogRocket from 'logrocket';

import { type Session } from '@/types/session';
import { useHubspotMutation } from '@/hooks/api/mutations/useHubspotMutation';
import { queryKeys } from '@/hooks/api/queryKeys';
import { shouldSendAnalytics } from '@/utils/analytics';
import { setDefaultAxiosUrlBase } from '@/utils/axiosConfig';
import { dateCreatedFromMongoId } from '@/utils/dateCreatedFromMongoId';
import { ensureFlagsmithIdentity, getAllEnabledFlags } from '@/utils/flagsmith';
import { getReferral } from '@/utils/querystrings';
import { isRudderStackEnabled, rudderStackAnalytics } from '@/utils/rudderstack';

export const sessionDefault: Session = {
  account: {
    id: '',
    name: '',
    slug: '',
    status: '',
    settings: {
      hipaa: {
        enabled: false,
        region: null
      }
    },
    productPlan: {
      id: ''
    }
  },
  user: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    avatarUri: '',
    isTwoFactorAuthEnabled: false,
    isGoogleUser: false,
    hasSetPassword: false,
    marketing: undefined
  }
};

type PrefixedRecord<T, P extends string> = {
  [K in keyof T as `${P}_${string & K}`]: T[K];
};

function addPrefix<T extends object, P extends string>(obj: T, prefix: P): PrefixedRecord<T, P> {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [`${prefix}_${k}`, v])
  ) as PrefixedRecord<T, P>;
}

export async function getSession(accountSlug?: string) {
  let endpoint = `/v1/dashboard/session/state`;

  if (accountSlug) {
    endpoint += `?account_slug=${accountSlug}`;
  }

  const { data } = await axios.get<{ session: Session }>(endpoint, {
    withCredentials: true
  });

  return data.session;
}

export function useSessionQuery() {
  const flagsmith = useFlagsmith();
  const hubspotMutation = useHubspotMutation();

  const params = new URLSearchParams(window.location.search);
  const accountSlug = params.get('account') ?? undefined;

  const query = useQuery({
    queryKey: [queryKeys.auth.session],
    queryFn: () => getSession(accountSlug),

    // prevent fetching the data each time this hook is called
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,

    // refetch in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 8,
    placeholderData: sessionDefault
  });

  const identifyHubspotUser = async (session: Session) => {
    const { account, user } = session;
    if (account) {
      const { email, firstName, lastName } = user;

      hubspotMutation.mutate(
        {
          email,
          firstName,
          lastName
        },
        {
          onSuccess: (hubspotToken: string) => {
            if (hubspotToken) {
              window.hsConversationsSettings = {
                identificationEmail: email,
                identificationToken: hubspotToken
              };
              if (!window.HubSpotConversations) {
                window.hsConversationsOnReady = [
                  () => {
                    window.HubSpotConversations.widget.load();
                  }
                ];
              }
              const status = window.HubSpotConversations?.widget.status();

              if (status?.loaded) {
                window.HubSpotConversations.widget.refresh();
              } else {
                window.HubSpotConversations.widget.load();
              }
            }
          }
        }
      );
    }
  };

  useEffect(() => {
    const handleIdentifyFlagsmith = async (session: Session) => {
      if (session.account.id) {
        await ensureFlagsmithIdentity(session);
      }
    };

    const handleIdentifyAnalytics = async (session: Session) => {
      if (!shouldSendAnalytics(session.user.email)) {
        return;
      }

      await rudderStackAnalytics.identify(session.user.id, {
        referral: getReferral()
      });

      const flags = await getAllEnabledFlags();

      // Only call Segment to group the user if the user has an account id
      // Shared builder-only users don't have account data in the schema
      if (session.account.id !== '') {
        let groupTraits = {
          account_created: dateCreatedFromMongoId(session.account.id)
        };

        if (flagsmith.identity) {
          groupTraits = {
            ...groupTraits,

            // Add flagsmith_ prefix to all flag names to avoid confusion
            ...addPrefix(flags, 'flagsmith')
          };
        }

        groupTraits = {
          ...groupTraits,
          ...addPrefix(session.user.marketing?.questionnaire ?? {}, 'questionnaire')
        };

        await rudderStackAnalytics.group(session.account.id, groupTraits);
      }
    };

    if (query.data && query.data.user.id !== '') {
      const name = `${query.data.user.firstName} ${query.data.user.lastName}`;
      if (import.meta.env.PUBLIC_IS_PRODUCTION === 'true') {
        if (query.data.account.productPlan?.id !== 'trial') {
          void identifyHubspotUser(query.data);
        }
      }
      void handleIdentifyFlagsmith(query.data).then(() => handleIdentifyAnalytics(query.data));

      if (import.meta.env.PUBLIC_LOGROCKET_APP_ID && !query.data.account.settings?.hipaa?.enabled) {
        LogRocket.identify(query.data.user.id, {
          name,
          email: query.data.user.email,
          account_id: query.data.account.id,
          plan_id: query.data.account.productPlan.id
        });
      }

      // If the account is hipaa we should use the region specified in the settings
      if (query.data.account.settings?.hipaa?.enabled) {
        const region =
          query.data.account.settings.hipaa.region === 'us-govcloud'
            ? 'usgc'
            : query.data.account.settings.hipaa.region;
        const originalUrl = import.meta.env.PUBLIC_API_URL;
        const convertedUrl = originalUrl.replace('api', `${region}-api`);

        setDefaultAxiosUrlBase(convertedUrl);
      } else {
        setDefaultAxiosUrlBase(import.meta.env.PUBLIC_API_URL);
      }
    } else if (isRudderStackEnabled()) {
      rudderStackAnalytics.reset();
    }
  }, [query.data]);

  return query;
}
