import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HiPlayCircle as PlayIcon, HiQuestionMarkCircle as QuestionIcon } from 'react-icons/hi2';
import { show as showIntercom } from '@intercom/messenger-js-sdk';
import { Button } from '@knack/asterisk-react';

import { useChecklists } from '@/hooks/checklists/useChecklists';
import { QUICK_START_VIDEO_URL } from '@/utils/constants';
import { ChecklistsExpandedSection } from '@/components/checklists/ChecklistsExpandedSection';
import { ChecklistsSection } from '@/components/checklists/ChecklistsSection';
import { ChecklistsTitle } from '@/components/checklists/ChecklistsTitle';
import { shouldInstallIntercom } from '@/IntercomProvider';

type Props = {
  onSectionClick: (section: string) => void;
};

export function ChecklistsMainContent({ onSectionClick }: Props) {
  const [t] = useTranslation();
  const { sections } = useChecklists();
  const sectionIds = useMemo(() => Object.keys(sections), [sections]);

  return (
    <>
      <ChecklistsTitle />
      {sectionIds.length === 1 ? (
        <ChecklistsExpandedSection id={sectionIds[0]} canGoBack={false} />
      ) : (
        <div className="mt-2 flex flex-col gap-2">
          {sectionIds.map((sectionId) => (
            <ChecklistsSection
              key={sectionId}
              onClick={() => onSectionClick(sectionId)}
              sectionId={sectionId}
            />
          ))}
        </div>
      )}
      <div className="flex flex-col items-start gap-2">
        <p className="text-xs">{t('components.checklists.links.title')}</p>
        <Button intent="link" className="text-gradient-1" asChild>
          <a href={QUICK_START_VIDEO_URL} target="_blank" rel="noreferrer">
            <Button.Icon icon={PlayIcon} className="mr-1 fill-[url(#svg-gradient-1)]" />
            {t('components.checklists.links.demo')}
          </a>
        </Button>
        <Button
          intent="link"
          className="text-gradient-1"
          onClick={() => {
            if (shouldInstallIntercom()) {
              showIntercom();
            }
          }}
        >
          <Button.Icon icon={QuestionIcon} className="mr-1 fill-[url(#svg-gradient-1)]" />
          {t('components.checklists.links.chat')}
        </Button>
      </div>
    </>
  );
}
