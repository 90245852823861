import { type CSSProperties } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { type BillingPlan } from '@/types/account';
import { type InvoiceLineItem } from '@/types/billing';

interface InvoicePdfLayoutProps {
  invoice: any;
  billingCustomInfo: string;
  currentApiPlan?: BillingPlan;
}

export function InvoicePdfLayout({
  invoice,
  billingCustomInfo,
  currentApiPlan
}: InvoicePdfLayoutProps) {
  const [t] = useTranslation();

  const invoiceDate = new Date(invoice.date * 1000).toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });

  const invoiceTax = invoice.tax ? `$${Number(invoice.tax / 100).toFixed(2)}` : undefined;
  let invoiceTotal = `$${Number(invoice.total / 100).toLocaleString(undefined, {
    minimumFractionDigits: 2
  })}`;
  if (invoice.total < 0) {
    invoiceTotal = invoiceTotal.replace('$-', '-$');
  }

  let invoiceItems: InvoiceLineItem[] = [];
  if (invoice.lines.invoiceitems?.length) {
    invoiceItems = invoice.lines.invoiceitems;
  }

  if (invoice.lines.subscriptions?.length) {
    invoiceItems = [
      ...invoiceItems,
      ...invoice.lines.subscriptions.filter(
        (item: InvoiceLineItem) =>
          !invoiceItems.some((invoiceItem: InvoiceLineItem) => invoiceItem.id === item.id)
      )
    ];
  }

  if (invoice.lines.data?.length) {
    invoiceItems = [
      ...invoiceItems,
      ...invoice.lines.data.filter(
        (item: InvoiceLineItem) =>
          !invoiceItems.some((invoiceItem: InvoiceLineItem) => invoiceItem.id === item.id)
      )
    ];
  }

  const tableStyles: CSSProperties = {
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: '0',
    border: 'none'
  };

  return (
    <div>
      <table style={tableStyles}>
        <tbody>
          <tr>
            <td width="50%">
              <strong style={{ fontSize: '52px' }}>Knack</strong>
              <br />
              500 Westover Drive #15397
              <br />
              Sandford, North Carolina 27330
              <br />
              United States
              <br />
              billing@knack.com
            </td>
            <td style={{ textAlign: 'right', verticalAlign: 'top' }}>
              <strong style={{ fontSize: '52px' }}>
                {t('components.billing.invoices.invoice_caps')}
              </strong>
              <br />
              {invoice.number}
              <br />
              {invoiceDate}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <br />

      <table style={tableStyles}>
        <tbody>
          <tr>
            <td>
              <strong> {t('components.billing.invoices.bill_to')}</strong>
              <br />
              {billingCustomInfo}
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <hr />
      <p>&nbsp;</p>

      <table style={tableStyles}>
        <tbody>
          <tr style={{ borderBottom: '1px solid #ccc' }}>
            <th style={{ width: '80%', textAlign: 'left' }}>
              <strong>{t('components.billing.invoices.description_caps')}</strong>
              <br />
            </th>
            <th style={{ width: '20%', textAlign: 'right' }}>
              <strong> {t('components.billing.invoices.rate_caps')}</strong>
              <br />
            </th>
          </tr>

          {invoiceItems?.map((item: any) => {
            let itemAmount = `$${Number(item.amount / 100).toLocaleString(undefined, {
              minimumFractionDigits: 2
            })}`;
            if (item.amount < 0) {
              itemAmount = itemAmount.replace('$-', '-$');
            }

            let planTitle;
            if (item.plan) {
              // Check if the invoice is for the API add-on plan
              if (currentApiPlan?.id === item.plan.id) {
                planTitle = `API - ${item.quantity * 25}k/day Monthly Plan`;
              } else {
                const planAmount = `$${Number(item.plan.amount / 100).toLocaleString(undefined, {
                  minimumFractionDigits: 2
                })}`;
                planTitle = `${item.plan.name} Plan @ ${planAmount}/${item.plan.interval}`;
              }
            }

            const periodStart = new Date(item.period.start * 1000).toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            });

            const periodEnd = new Date(item.period.end * 1000).toLocaleDateString(undefined, {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            });

            return (
              <tr key={item.id}>
                <td style={{ paddingBottom: '10px' }}>
                  {planTitle}
                  <br /> {`${periodStart} - ${periodEnd}`}
                  {item.description && (
                    <>
                      <br /> {item.description}
                    </>
                  )}
                  <br />
                </td>
                <td style={{ paddingBottom: '10px', textAlign: 'right' }}>
                  {itemAmount}
                  <br />
                  {invoice.discount?.coupon && (
                    <>
                      <br />
                      {invoice.discount.coupon.amount_off && (
                        <>
                          {`-$${Number(invoice.discount.coupon.amount_off / 100).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2
                            }
                          )}`}
                          <br />
                        </>
                      )}
                      {invoice.discount.coupon.percent_off && (
                        <>
                          {`-${invoice.discount.coupon.percent_off}%`}
                          <br />
                        </>
                      )}
                      ({invoice.discount.coupon.name})
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />

      <div style={{ textAlign: 'right' }}>
        {invoiceTax && (
          <p>
            <strong>Tax: {invoiceTax}</strong>
          </p>
        )}
        <p>
          <strong>
            {t('components.billing.invoices.total')}
            &nbsp;
            {invoiceTotal}
          </strong>
        </p>
        <p>
          <strong>
            {t('components.billing.invoices.amount_paid')}
            &nbsp;
            {invoiceTotal}
          </strong>
        </p>
        <p>
          <strong>{t('components.billing.invoices.amount_due', { amount: 0 })}</strong>
        </p>
      </div>
      <br />
      <br />
      <br />
      <hr />
      <p>&nbsp;</p>
      <p>
        <Trans i18nKey="components.billing.invoices.thanks_for_being_a_costumer">
          <a
            href="mailto:support@knack.com"
            target="_blank"
            rel="noreferrer"
            className="text-black underline"
          >
            <strong>Knack support</strong>
          </a>
        </Trans>
      </p>
    </div>
  );
}
