import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiMiniLightBulb as LightBulbIcon } from 'react-icons/hi2';
import { IoCopy as CopyIcon } from 'react-icons/io5';
import { Button, Tooltip, useToast } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { AI_CAROUSEL_SUGGESTIONS_PROMPTS } from './aiConstants';

interface AISuggestionCarouselItemProps {
  text: string;
}

function AISuggestionCarouselItem({ text }: AISuggestionCarouselItemProps) {
  const [t] = useTranslation();
  const { presentToast } = useToast();
  const copyToClipboard = async () => {
    if (!text) return;
    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(text);
      }
      presentToast({
        title: t('actions.copy_clipboard_success')
      });
    } catch (error) {
      presentToast({
        title: t('actions.copy_clipboard_error')
      });
    }
  };

  return (
    <div className="flex w-full items-start">
      <div className="flex-4 pr-4">
        <p className="text-xs opacity-100">{text}</p>
      </div>
      <div className="flex-1 text-right">
        <Tooltip>
          <Tooltip.Trigger>
            <CopyIcon
              className="m-0 text-sm opacity-100 hover:text-emphasis"
              onClick={copyToClipboard}
            />
          </Tooltip.Trigger>
          <Tooltip.Content>{t('actions.copy_clipboard')}</Tooltip.Content>
        </Tooltip>
      </div>
    </div>
  );
}

export function AISuggestionsCarousel() {
  const [t] = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isCarouselVisible, setIsCarouselVisible] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % AI_CAROUSEL_SUGGESTIONS_PROMPTS.length);
    }, 4000);

    return () => clearInterval(interval);
  }, []);

  const handleDotClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <>
      <div className="mb-3 flex items-center gap-0 space-x-2 fill-[url(#svg-gradient-1)]">
        <LightBulbIcon className="fill-[url(#svg-gradient-1)]" />
        <Button intent="minimalStandalone" onClick={() => setIsCarouselVisible(!isCarouselVisible)}>
          <span className="text-gradient-1 underline">
            {t('components.create_app.ai.examples_title')}
          </span>
        </Button>
      </div>
      {isCarouselVisible && (
        <div className="relative overflow-hidden rounded-br-[15px] rounded-tl-[15px] bg-brand-muted p-2.5 text-default shadow hover:bg-gradient-3 hover:text-white">
          <div
            className="max-h-16 max-w-[437px] transition-transform duration-500 ease-in-out"
            key={activeIndex}
          >
            <AISuggestionCarouselItem
              key={AI_CAROUSEL_SUGGESTIONS_PROMPTS[activeIndex].id}
              text={t(AI_CAROUSEL_SUGGESTIONS_PROMPTS[activeIndex].i18nKey)}
            />
          </div>
        </div>
      )}
      {isCarouselVisible && (
        <div className="flex-center absolute left-1/2 mt-2 flex -translate-x-1/2 transform gap-2">
          {AI_CAROUSEL_SUGGESTIONS_PROMPTS.map((suggestion, index) => (
            <Button
              size="sm"
              key={suggestion.id}
              className={cn('size-1 rounded-full bg-emphasis', {
                'bg-brand': index === activeIndex
              })}
              onClick={() => handleDotClick(index)}
            />
          ))}
        </div>
      )}
    </>
  );
}
