import { type SampleApp } from '@/types/apps';
import { capitalizeAll } from '@/utils/formatters';

export const getSampleWelcomePath = (sample: SampleApp) => {
  const tableName = sample.table_name ? `the ${capitalizeAll(sample.table_name)}` : 'your first';
  const roleName = sample.user_role ? `the ${capitalizeAll(sample.user_role)}` : 'a default';
  const buttonType = sample.button_type ?? 'builder';

  return `welcome?type=template&app-name=${sample.title}&table=${tableName}&user-role=${roleName}&button-type=${buttonType}`;
};
