export const AI_CAROUSEL_SUGGESTIONS_PROMPTS = [
  {
    id: 1,
    i18nKey: 'components.create_app.ai.carousel.suggestions.1'
  },
  {
    id: 2,
    i18nKey: 'components.create_app.ai.carousel.suggestions.2'
  },
  {
    id: 3,
    i18nKey: 'components.create_app.ai.carousel.suggestions.3'
  }
] as const;

export const MAX_AVERAGE_WORD_LENGTH = 20;
export const MIN_CHARACTER_COUNT = 60;
export const MAX_CHARACTERS_COUNT = 400;
export const ILLEGAL_CHARACTERS_REGEX = /[<>]/;
