import { useCallback, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { boot, Intercom, shutdown, update } from '@intercom/messenger-js-sdk';

import { Route } from '@/enums';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';

// Routes where the Intercom will not be displayed
const DEACTIVATED_ROUTES = [`/${Route.SetupWizard}`];

export function shouldInstallIntercom() {
  return import.meta.env.PUBLIC_INTERCOM_APP_ID !== '';
}

export function IntercomProvider({ children }: { children: React.ReactNode }) {
  const { data: session } = useSessionQuery();
  const { data: account } = useAccountQuery();
  const isAuthenticated = !!session?.user.id;
  const { pathname } = useLocation();

  const isIntercomActive = useMemo(
    () =>
      shouldInstallIntercom() &&
      isAuthenticated &&
      account?.product_plan?.id === 'trial' &&
      !DEACTIVATED_ROUTES.includes(pathname),
    [account, isAuthenticated, pathname]
  );

  const getInitParams = useCallback(
    () =>
      account
        ? {
            name: `${account.session.user.first_name ?? ''} ${account.session.user?.last_name ?? ''}`.trim(),
            email: account.session.user.email,
            user_id: account.session.user.id,
            account: account.slug // Custom attribute
          }
        : null,
    [account]
  );

  // This is meant to be called on every render as detailed by the package documentation:
  // > The package keeps track of any instances needed internally, therefore re-renders due
  // > to DOM changes won't trigger re-boot of the messenger.
  // See https://www.npmjs.com/package/@intercom/messenger-js-sdk
  useEffect(() => {
    const params = getInitParams();

    if (!isIntercomActive || !params) {
      return;
    }

    Intercom({
      app_id: import.meta.env.PUBLIC_INTERCOM_APP_ID
    });
  });

  useEffect(() => {
    const params = getInitParams();

    if (!isIntercomActive || !params) {
      shutdown();

      return;
    }

    boot({
      app_id: import.meta.env.PUBLIC_INTERCOM_APP_ID
    });
    update({
      ...params
    });
  }, [getInitParams, isIntercomActive, isAuthenticated, pathname]);

  return children;
}
