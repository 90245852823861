import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

import { type AddonsType } from '@/enums/addons';
import { queryKeys } from '@/hooks/api/queryKeys';

export type SetAddonLimitPayload = {
  quantity: number;
  planType: AddonsType;
};

type ChangeAutoIncreasePayload = {
  autoIncrease: boolean;
  planType: AddonsType;
};

type TransactionPackPayload = {
  amount: number;
  quantity: number;
  id: string;
};

export type UpdateTransactionsPackPayload = {
  transactionsPacks?: TransactionPackPayload[];
};

export type UpdateTransactionsPackResponse = {
  success: boolean;
  transactionsDiff: number;
};

async function setAddonLimit(payload: SetAddonLimitPayload) {
  const { data } = await axios.post('/v1/dashboard/billing/set-addon-limit', payload, {
    withCredentials: true
  });

  return data.success;
}

async function removeAddon(planType: AddonsType) {
  const { data } = await axios.delete(`/v1/dashboard/billing/remove-addon/${planType}`, {
    withCredentials: true
  });

  return data.success;
}

async function changeAutoIncrease(planType: AddonsType, autoIncrease: boolean) {
  const { data } = await axios.put(
    `/v1/dashboard/billing/change-auto-increase/${planType}`,
    { autoIncrease },
    {
      withCredentials: true
    }
  );

  return data.success;
}

async function updateTransactionPacks(payload: UpdateTransactionsPackPayload) {
  const { data } = await axios.post<UpdateTransactionsPackResponse>(
    '/v1/dashboard/billing/transactions-pack',
    { transactionsPacks: payload.transactionsPacks },
    { withCredentials: true }
  );

  return data;
}

export function useAddonPlanMutation() {
  const queryClient = useQueryClient();

  const onAddonPlanChangeSuccess = () => {
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.account]
    });
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.billing.overview]
    });
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.billing.apiPlan]
    });
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.billing.records]
    });
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.billing.storage]
    });
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.billing.transactions]
    });
    void queryClient.invalidateQueries({
      queryKey: [queryKeys.billing.addons]
    });
  };

  const setAddonMutation = useMutation({
    mutationFn: (payload: SetAddonLimitPayload) => setAddonLimit(payload),
    onSuccess: () => {
      onAddonPlanChangeSuccess();
    }
  });

  const removeAddonMutation = useMutation({
    mutationFn: (planType: AddonsType) => removeAddon(planType),
    onSuccess: () => {
      onAddonPlanChangeSuccess();
    }
  });

  const changeAutoIncreaseMutation = useMutation({
    mutationFn: (payload: ChangeAutoIncreasePayload) =>
      changeAutoIncrease(payload.planType, payload.autoIncrease),
    onSuccess: () => {
      onAddonPlanChangeSuccess();
    }
  });

  const updateTransactionPacksMutation = useMutation({
    mutationFn: (payload: UpdateTransactionsPackPayload) => updateTransactionPacks(payload),
    onSuccess: () => {
      onAddonPlanChangeSuccess();
    }
  });

  return {
    setAddonMutation,
    removeAddonMutation,
    changeAutoIncreaseMutation,
    updateTransactionPacksMutation
  };
}
