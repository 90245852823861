import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiInformationCircle as InfoIcon } from 'react-icons/hi2';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Banner, Button, Card, Spinner, Tabs, Tooltip } from '@knack/asterisk-react';

import { AddonsType } from '@/enums/addons';
import { type Account } from '@/types/account';
import { Flows } from '@/hooks/api/queries/useFlowsQuery';
import { BILLING_PAGE_TABS } from '@/hooks/usePageTabs';
import { isFlagEnabled } from '@/utils/flagsmith';
import { formatNumberForStorage } from '@/utils/formatters';
import { AutoIncrease } from '@/pages/settings/billing/addons/auto-increase/AutoIncrease';
import { FlowsHistoryDialog } from './FlowsHistoryDialog';
import { getFullTransactionsUsedBannerText, getRenewalDay } from './usageHelper';

interface PlanUsageProps {
  accountData: Account;
  cardTitleClasses?: string;
}

/**
 * Query param that may be included in the URL to redirect
 * the user to the flows tab by default
 */
type TabOptions = 'flows';

export function PlanUsage({ accountData, cardTitleClasses }: PlanUsageProps) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = searchParams.get('tab') as TabOptions | null;

  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);
  const isFlowsEnabled =
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test' ||
    isFlagEnabled('albato_flows');
  const isAutoIncreaseChecked = accountData.billing?.auto_increase?.transactions ?? false;
  const { search } = useLocation();

  const isAutoIncreaseDisabled =
    accountData.isTrial ||
    !accountData.product_plan ||
    accountData.product_plan?.id.includes('starter_') ||
    (accountData.product_plan.level > 3 && accountData.product_plan.type === 'custom');
  const { data: transactionsUsage, isPending: isTransactionsUsageLoading } =
    Flows.useTransactionsUsageQuery(accountData);

  const { data: flowsStatus } = Flows.useAccountFlowsStatusQuery();
  const transactionsUsagePercentage = transactionsUsage?.trxPercentageUsageCurrentMonth ?? 0;
  const isStarter = accountData?.product_plan?.id.includes('starter') ?? false;
  const {
    appsLimit,
    isHipaa,
    isInternalAccount,
    isPrivatePlan,
    isTrial,
    numberOfApps,
    numberOfRecords,
    recordsLimit,
    storageLimit,
    storageLimitInBytes,
    storagePercent,
    storageUsedInBytes,
    totalDailyApiLimit
  } = accountData;

  const [activeTab, setActiveTab] = useState(
    isFlowsEnabled && tabParam === 'flows' ? 'flows' : 'general'
  );

  const tabItems = [
    {
      children: <span>{t('components.billing.overview.flows.tabs.general')}</span>,
      value: 'general'
    }
  ];

  if (isFlowsEnabled) {
    tabItems.push({
      children: <span>{t('components.billing.overview.flows.tabs.flows')}</span>,
      value: 'flows'
    });
  }

  useEffect(() => {
    if (tabParam && (!isFlowsEnabled || tabParam !== 'flows')) {
      setActiveTab('general');
    }
    searchParams.delete('tab');
    setSearchParams(searchParams);
  }, [isFlowsEnabled, tabParam, activeTab]);

  return (
    <Card className="flex flex-col p-6 sm:p-6" data-testid="plan-usage-apps">
      {isHistoryDialogOpen && (
        <div className="size-full">
          <FlowsHistoryDialog isOpen={isHistoryDialogOpen} setIsOpen={setIsHistoryDialogOpen} />
        </div>
      )}
      <h2 className={cardTitleClasses}>{t('components.billing.overview.plan_usage')}</h2>

      {isFlowsEnabled && (
        <Tabs defaultValue={activeTab} className="mb-6 w-[155px]" onValueChange={setActiveTab}>
          <Tabs.List items={tabItems}>
            <Tabs.Content value="general" data-testid="button-tab-general" />
            <Tabs.Content value="flows" data-testid="button-tab-flows" />
          </Tabs.List>
        </Tabs>
      )}

      {activeTab === 'general' && (
        <>
          <div className="mb-6">
            <span className="mb-1 block text-subtle">{t('components.apps.apps')}</span>
            {isPrivatePlan ? (
              <p>{numberOfApps}</p>
            ) : (
              <>
                <p>
                  {t('components.billing.overview.apps_amount_available', {
                    n: numberOfApps.toLocaleString(),
                    total: appsLimit.toLocaleString()
                  })}
                </p>
                {numberOfApps > appsLimit && (
                  <div className="text-destructive">
                    <p>{t('components.billing.overview.apps_exceeding')}</p>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="mb-6" data-testid="plan-usage-records">
            <span className="mb-1 block text-subtle">
              {t('components.billing.overview.records')}
            </span>
            {isPrivatePlan ? (
              <p>{numberOfRecords.toLocaleString()}</p>
            ) : (
              <>
                <p>
                  {t('components.billing.overview.records_amount_available', {
                    n: numberOfRecords.toLocaleString(),
                    total: recordsLimit.toLocaleString()
                  })}
                </p>
                {numberOfRecords > recordsLimit && (
                  <div className="text-destructive">
                    <p>{t('components.billing.overview.records_exceeding')}</p>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="mb-6" data-testid="plan-usage-storage">
            <span className="mb-1 block text-subtle">
              {t('components.billing.overview.storage')}
            </span>
            {isPrivatePlan ? (
              <p>{storageUsedInBytes}</p>
            ) : (
              <>
                <p>
                  {t('components.billing.overview.storage_amount_available', {
                    percentage: storagePercent,
                    kb: formatNumberForStorage(storageUsedInBytes),
                    total: storageLimit
                  })}
                </p>
                {storageUsedInBytes > storageLimitInBytes && (
                  <div className="text-destructive">
                    <p>{t('components.billing.overview.storage_exceeding')}</p>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="mb-6">
            <span className="mb-1 block text-subtle">
              {t('components.billing.overview.api_calls')}
            </span>
            <p className="mb-1">
              {t('components.billing.overview.api_addon_daily_limit_total', {
                numberOfCalls: totalDailyApiLimit.toLocaleString()
              })}
            </p>
            <p className="text-subtle">
              {t('components.billing.overview.api_addon_daily_limit_more_info')}
            </p>
          </div>
        </>
      )}

      {activeTab === 'flows' && isFlowsEnabled && (
        <div className="mb-6">
          <h2 className="text-content mb-2 mt-2 block">
            {t('components.billing.overview.flows.title')}
            <Tooltip>
              <Tooltip.Trigger className="ml-1 inline-block max-w-[30ch] truncate align-middle text-subtle">
                <InfoIcon className="text-subtle" size={18} />
              </Tooltip.Trigger>
              <Tooltip.Content className="max-w-[35ch] justify-center bg-gray-900 text-white">
                {t('components.billing.overview.flows.additional_explanation')}
              </Tooltip.Content>
            </Tooltip>
          </h2>
          <div className="mb-6">
            <span className="mb-1 block text-subtle">
              {isTransactionsUsageLoading ? (
                <Spinner size="sm" />
              ) : (
                t('components.billing.overview.flows.usage', {
                  used: transactionsUsage?.trxUsageCurrentMonth ?? 0,
                  total: transactionsUsage?.trxLimitCurrentMonth ?? 0
                })
              )}
              <Button
                className="ml-2"
                intent="link"
                size="sm"
                onClick={() => setIsHistoryDialogOpen(true)}
              >
                {t('components.billing.overview.flows.history_button')}
              </Button>
            </span>
          </div>
          {transactionsUsage?.datePaidPeriodEnd && (
            <p className="mb-6 text-subtle">
              {t('components.billing.overview.flows.description', {
                day: getRenewalDay(transactionsUsage.datePaidPeriodEnd)
              })}
            </p>
          )}
          {transactionsUsagePercentage >= 50 && (
            <Banner
              intent={transactionsUsagePercentage >= 90 ? 'destructive' : 'warning'}
              className="my-4 block gap-3"
            >
              {getFullTransactionsUsedBannerText(accountData, t, transactionsUsagePercentage)}
            </Banner>
          )}
          {transactionsUsage && (
            <div className="mb-6 justify-between">
              <AutoIncrease
                addonType={AddonsType.Transactions}
                checked={isAutoIncreaseChecked}
                disabled={isAutoIncreaseDisabled}
                renewalDay={getRenewalDay(transactionsUsage.datePaidPeriodEnd)}
              />
            </div>
          )}
        </div>
      )}

      {!isTrial && !isInternalAccount && !isStarter && (
        <div className="mt-auto flex">
          {activeTab === 'general' && (
            <Button
              intent="secondary"
              size="sm"
              disabled={isHipaa}
              className="grow"
              onClick={() =>
                navigate({
                  pathname: `${BILLING_PAGE_TABS.baseUrl}/${BILLING_PAGE_TABS.tabs.AddOns.path}`,
                  search
                })
              }
            >
              {t('components.billing.overview.purchase_addons')}
            </Button>
          )}
          {activeTab === 'flows' && flowsStatus?.isInitialized && (
            <Button
              intent="secondary"
              size="sm"
              disabled={isHipaa}
              className="grow"
              onClick={() =>
                navigate({
                  pathname: `${BILLING_PAGE_TABS.baseUrl}/${BILLING_PAGE_TABS.tabs.AddOns.path}`,
                  search
                })
              }
            >
              {t('components.billing.overview.flows.purchase')}
            </Button>
          )}
        </div>
      )}
    </Card>
  );
}
