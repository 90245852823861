import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { type Account } from '@/types/account';
import { type AccountFlowsStatus, type TransactionsUsage } from '@/types/billing';
import { queryKeys } from '@/hooks/api/queryKeys';
import { useSession } from '@/hooks/useSession';
import { isFlagEnabled } from '@/utils/flagsmith';

async function getTransactionsUsage() {
  const isFlowsEnabled = isFlagEnabled('albato_flows');
  if (!isFlowsEnabled && process.env.NODE_ENV !== 'development') {
    return null;
  }

  const { data } = await axios.get<TransactionsUsage>('/v1/flows/usage', {
    withCredentials: true
  });

  return data;
}

async function getHistoryIframeURL(accountId: string) {
  const { data } = await axios.post<{ flowsUrl: string }>(
    `/v1/flows/${accountId}/get-history`,
    null,
    {
      withCredentials: true
    }
  );

  return data;
}

async function getAccountFlowsStatus() {
  const { data } = await axios.get<AccountFlowsStatus>('/v1/flows/get-account-status', {
    withCredentials: true
  });

  return data;
}

export function useTransactionsUsageQuery(account: Account) {
  return useQuery({
    queryKey: [queryKeys.flows.transactionsUsage, account.product_plan?.id, account.billing?.plans],
    queryFn: getTransactionsUsage,
    staleTime: 1000 * 30,
    enabled: !!account.product_plan?.id
  });
}

export function useGetHistoryURLQuery() {
  const session = useSession();

  return useQuery({
    queryKey: [queryKeys.flows.history, session.account.id],
    queryFn: () => getHistoryIframeURL(session.account.id)
  });
}

export function useAccountFlowsStatusQuery() {
  return useQuery({
    queryKey: [queryKeys.flows.account_status],
    queryFn: getAccountFlowsStatus
  });
}

export const Flows = {
  useTransactionsUsageQuery,
  useGetHistoryURLQuery,
  useAccountFlowsStatusQuery
};
