import { type SampleApp } from '@/types/apps';
import {
  BASE_IMAGE_URL,
  TEMPLATES_BY_INDUSTRY,
  TEMPLATES_IMG_FORMAT
} from '@/pages/apps/create-app/constants';

export const renameFilterBeforeCheckTemplate = (key: string): string => {
  const keyMappings = {
    directories: 'directory',
    equations: 'calculator',
    portals: 'portal',
    payments: 'payment',
    'e-commerce': 'payment',
    education: 'schools'
  } as const;

  return keyMappings[key] || key;
};

export const getUrlForAICard = (selectedUseCase: string, isDarkMode: boolean) => {
  const useCaseKey = renameFilterBeforeCheckTemplate(selectedUseCase);
  if (useCaseKey === 'online-database') {
    return `${BASE_IMAGE_URL}${isDarkMode ? 'dark' : 'light'}-other.svg`;
  }
  return `${BASE_IMAGE_URL}${isDarkMode ? 'dark' : 'light'}-${useCaseKey}.svg`;
};

// Gets the 3 best scored templates for the selected industry, provided by Product team
export const getTemplateAppsFilteredAndSorted = (
  selectedIndustry: string,
  sampleApps: SampleApp[] = [],
  shouldShowAllTemplates: boolean = false
): SampleApp[] => {
  if (!selectedIndustry || sampleApps.length === 0) return [];

  const sortByScore = (a: SampleApp, b: SampleApp) => (b.score ?? 0) - (a.score ?? 0);

  const getAllTemplatesForIndustry = () => {
    if (selectedIndustry === 'other') {
      return [...sampleApps].sort(sortByScore);
    }

    const industryCategory = renameFilterBeforeCheckTemplate(selectedIndustry);
    return [...sampleApps]
      .filter((app) => app.categories?.includes(industryCategory))
      .sort(sortByScore);
  };

  const getTopTemplatesForIndustry = () => {
    const industryTemplates = TEMPLATES_BY_INDUSTRY[selectedIndustry];
    return industryTemplates
      ? sampleApps.filter((app) => industryTemplates.includes(app.slug))
      : [];
  };

  return shouldShowAllTemplates ? getAllTemplatesForIndustry() : getTopTemplatesForIndustry();
};

// Given the link that is the full URL of the template, return name and format of the file
// In the public-cdn repo, as the slug located there is the urlSlug, not the appSlug
export const getCardImage = (link: string) => {
  const image = TEMPLATES_IMG_FORMAT.find((img) => link.includes(img.urlSlug));
  if (!image) {
    return null;
  }
  return image;
};
